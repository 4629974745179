import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyios, displayError, displaySuccess, exportPhysio, getUnverifiedPhyios, getUserDetail, getPhysioDetail, getNriUserDetails } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../config/config';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

const NriViewUser = (props) => {
  const [users, setUsers] = useState('');
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState('');

  const getList = async (search) => {
    props.toggleLoader(true);
    var getUsers_var = await getNriUserDetails(props.match.params.id);
    setUsers(getUsers_var?.data)
    console.log(getUsers_var?.data, "====ppp")
    props.toggleLoader(false);
  }

  useEffect(() => {
    getList()
  }, [props])


  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard-nri"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="/nri-bookings">Bookings</a></li>
                          <li className="breadcrumb-item active" aria-current="page">View User</li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">{users?.role == 'user' ? 'User' : null}</h3>
                        </div>

                      </div>
                    </div>
                    <div className="card-body">
                      {/* <div className="row"> */}
                      <div className="col-md-9">
                        <div>
                          {users?.photo_url !== undefined ?
                            <img src={users?.photo_url} style={{
                              width: 210,
                              height: 210,
                              borderRadius: 110,
                              border: '2px #387ebc solid',
                              // padding: 5
                            }} />
                            :
                            <div className='name-letter' style={{
                              width: 210,
                              height: 210,
                              borderRadius: 110,
                              border: '2px #387ebc solid',
                              padding: 5, fontSize: 34
                            }}>
                              {users?.first_name?.charAt(0)?.toUpperCase()}
                            </div>}
                        </div>
                        <div className="doc_type mt-3 mb-3">{users.full_name}</div>
                        {
                          users?.email &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Email :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.email}</label>
                            </div>
                          </div>
                        }

                        {users.phone_number &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Phone Number :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.phone_number}</label>
                            </div>
                          </div>}
                        {/* <div className='row'>
                          <div className='col-3'>
                            <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>DOB :</label>
                          </div>
                          <div className='col-8'>
                            <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.dob}</label>
                          </div>
                        </div> */}
                        {users.gender &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Gender :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.gender}</label>
                            </div>
                          </div>}
                        {users.dob &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Date of Birth :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{moment(users.dob).format('DD MMM YYYY')}</label>
                            </div>
                          </div>}
                        {users?.members &&
                          <>
                            <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0, marginTop: 20 }}>Members ({users?.members.length})</label>

                            <div className="table-responsive">
                              <table className="table align-items-center table-flush">
                                <thead className="thead-light">
                                  <tr>
                                    {/* <th>Sr no.</th> */}
                                    <th>Name</th>
                                    <th>Relation</th>
                                    <th>Address</th>
                                    <th>Pincode</th>
                                    <th>City</th>
                                    <th>State</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {users?.members && users?.members.map((a, i) => (

                                    <tr>
                                      {/* <td className="table-user">
                                        {++i}
                                      </td> */}
                                      <td className="table-user">
                                        {a?.name}
                                      </td>
                                      <td className="table-user">
                                        {a?.relation}
                                      </td>
                                      <td className="table-user">
                                        {a?.address}
                                      </td>
                                      <td className="table-user">
                                        {a?.pincode}
                                      </td>
                                      <td className="table-user">
                                        {a?.city}
                                      </td>
                                      <td className="table-user">
                                        {a?.state}
                                      </td>
                                    </tr>
                                  ))}

                                </tbody>
                              </table>
                            </div>
                          </>
                        }

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </main>
        </div >
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NriViewUser);
