import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyioKyc, displayError, displaySuccess, getNriInterest, getNriDetails, getProviderByInterest, getCouponList, getNriBookingById, removeSlots } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import TimePicker from 'react-time-picker';
import './style.css'
import DatePicker from 'react-datepicker';
import moment from 'moment';

const AssignProvider = (props) => {
  const queryParams = new URLSearchParams(window.location.search)
  const bookingId = queryParams.get("id")
  const interestId = queryParams.get("interest_id")
  const edit = queryParams.get("edit")
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [providers, setProviders] = useState([])
  const [providerId, setProviderId] = useState('');
  const [amount, setAmount] = useState('');
  const [couponArr, setCouponArr] = useState([]);
  const [couponId, setCouponId] = useState('');
  const [fromDate, setFromDate] = useState(new Date());
  const [fromTime, setFromTime] = useState(moment(new Date()).format('HH:mm'));
  const [toTime, setToTime] = useState(moment(new Date()).format('HH:mm'));
  const [slotArr, setSlotArr] = useState([]);
  const [newSlots, setNewSlots] = useState([]);

  // console.log(bookingId, interestId)
  const AddProvider = async () => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/nri/assign-booking';
    var token = localStorage.getItem('token');
    var data = {};
    data = {
      booking_id: bookingId,
      provider_id: providerId,
      amount: amount,
      coupon_id: couponId,
      slots: slotArr
    }
    axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() =>
          window.location.href = "/nri-bookings"
          , 3000)
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  const getProviders = async () => {
    var res = await getProviderByInterest(interestId)
    setProviders(res)
  }

  const getCoupons = async () => {
    var res = await getCouponList(interestId)
    setCouponArr(res)
  }

  useEffect(() => {
    if (interestId != undefined) {
      getProviders()
    }
  }, [interestId])

  useEffect(() => {
    getCoupons()
  }, [])

  const AddSlots = () => {
    var tempArr = [...slotArr]
    var isExist = tempArr.find((a) => a?.date == moment(fromDate).format('YYYY-MM-DD') && a?.fromTime == fromTime && a?.toTime == toTime)
    if (!isExist) {
      tempArr.push({
        date: moment(fromDate).format('YYYY-MM-DD'),
        fromTime: fromTime,
        toTime: toTime
      })
    }
    setSlotArr(tempArr)
  }

  const removeSlot = (a) => {
    var tempArr = [...slotArr]
    const index = tempArr.indexOf(a)
    tempArr.splice(index, 1)
    setSlotArr(tempArr)
  }

  const getBookingDetail = async () => {
    var res = await getNriBookingById(bookingId)
    setProviderId(res.data?.provider_id)
    setCouponId(res.data?.coupon_id)
    setAmount(res.data?.initial_amount)
    var tempArr = []
    res.data?.booking_slots?.map((slot) => {
      tempArr.push({
        id: slot?._id,
        date: slot?.date,
        fromTime: slot?.fromTime,
        toTime: slot?.toTime
      })
    })
    setSlotArr(tempArr)
  }
  useEffect(() => {
    if (edit !== undefined && edit == 'true') {
      getBookingDetail()
    }
  }, [edit])

  const deleteSlot = async (id, a) => {
    var res = await removeSlots(id)
    var tempArr = [...slotArr]
    const index = tempArr.indexOf(a)
    tempArr.splice(index, 1)
    setSlotArr(tempArr)

  }


  const UpdateProvider = async () => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/nri/update-booking';
    var token = localStorage.getItem('token');
    var data = {};
    data = {
      booking_id: bookingId,
      provider_id: providerId,
      slots: newSlots
    }
    axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() =>
          window.location.href = "/nri-bookings"
          , 3000)
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }


  const NewSlots = () => {
    var tempArr = [...slotArr]
    var newArr = [...newSlots]
    var isExist = tempArr.find((a) => a?.date == moment(fromDate).format('YYYY-MM-DD') && a?.fromTime == fromTime && a?.toTime == toTime)
    var isExistNew = newArr.find((a) => a?.date == moment(fromDate).format('YYYY-MM-DD') && a?.fromTime == fromTime && a?.toTime == toTime)
    if (!isExist) {
      tempArr.push({
        date: moment(fromDate).format('YYYY-MM-DD'),
        fromTime: fromTime,
        toTime: toTime
      })

    }
    if (!isExistNew) {
      newArr.push({
        date: moment(fromDate).format('YYYY-MM-DD'),
        fromTime: fromTime,
        toTime: toTime
      })
    }
    setSlotArr([...tempArr])
    setNewSlots(newArr)
  }

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard-nri"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/nri-bookings">Bookings </Link ></li>
                            <li className="breadcrumb-item"><Link to="/update-password">Assign Provider</Link ></li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Assign Provider</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Provider</label>
                              <select className="form-select select-dropdown" aria-label="Default select example"
                                value={providerId} onChange={(e) => setProviderId(e.target.value)}
                              >
                                <option value="" selected>Select Provider</option>
                                {providers && providers?.map((item) => {
                                  return <option value={item?._id}>{item?.name} {item?.interest ? `(${item?.interest?.name})` : null}</option>
                                })}
                              </select>

                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Coupon</label>
                              <select className="form-select select-dropdown" aria-label="Default select example"
                                value={couponId} onChange={(e) => setCouponId(e.target.value)}
                                disabled={edit == 'true' ? true : false}
                              >
                                <option value="" selected>Select Coupon</option>
                                {couponArr && couponArr?.map((item) => {
                                  return <option value={item?._id}>{item?.code}</option>
                                })}
                              </select>

                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Amount</label>
                              <input type="text" className="form-control" placeholder="Amount"
                                onChange={e => setAmount(e.target.value)} value={amount}
                                disabled={edit == 'true' ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="form-control-label" >Slots</label>
                              <div className="table-responsive">
                                <table className="table align-items-center table-flush">
                                  <thead className="thead-light">
                                    <tr>
                                      <th>sr no.</th>
                                      <th>Date</th>
                                      <th>From Time</th>
                                      <th>To Time</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {slotArr && slotArr.length > 0 ? slotArr.map((a, i) => (
                                      <tr>
                                        <td className="table-user">
                                          {++i}
                                        </td>
                                        <td className="table-user">
                                          {a?.date}
                                        </td>
                                        <td className="table-user">
                                          {a?.fromTime}
                                        </td>
                                        <td className="table-user">
                                          {a?.toTime}
                                        </td>
                                        <td className="table-user">
                                          {edit == 'true' ?
                                            <button className="btn btn-primary"
                                              style={{ fontSize: '11px' }}
                                              onClick={() => {
                                                deleteSlot(a?.id, a)

                                              }}
                                            > Remove </button>
                                            :
                                            <button className="btn btn-primary"
                                              style={{ fontSize: '11px' }}
                                              onClick={() => {
                                                removeSlot(a)

                                              }}
                                            > Remove </button>
                                          }
                                        </td>
                                      </tr>
                                    ))
                                      :
                                      <tr >
                                        <td colSpan={4} style={{ textAlign: 'center' }}>

                                          No Slots
                                        </td>
                                      </tr>
                                    }

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <label className="form-control-label" >Add Slots :</label>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="form-control-label" >Date</label>

                              <DatePicker
                                selected={fromDate}
                                dateFormat="yyyy-MM-dd"
                                onChange={(date) => setFromDate(date)}
                                minDate={new Date()}
                                customInput={
                                  <input
                                    // style={{ width: '360px' }}
                                    type="text"
                                    className="form-control"
                                    placeholder={('Date')}
                                    value={fromDate}
                                  />
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="form-control-label" >From Time</label>
                              <TimePicker onChange={(e) => setFromTime(e)} value={fromTime}
                                format="HH:mm" // Set 24-hour format
                              // disableClock={true}
                              />

                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="form-control-label" >To Time</label>
                              <TimePicker onChange={(e) => setToTime(e)} value={toTime}
                                format="HH:mm" // Set 24-hour format
                              // disableClock={true}
                              />

                            </div>
                          </div>
                          <div className="col-md-3 mt-2">
                            <button className="btn btn-primary mt-4"
                              onClick={() => {
                                if (edit == 'true') {
                                  NewSlots()
                                } else {
                                  AddSlots()
                                }

                              }}
                            > + Add </button>
                          </div>

                        </div>
                        {edit == 'true' ?
                          <button className="btn btn-primary mt-4"
                            onClick={() => {
                              UpdateProvider()

                            }}
                          > <i className="far fa-paper-plane" /> Update </button>
                          :
                          <button className="btn btn-primary mt-4"
                            onClick={() => {
                              AddProvider()

                            }}
                          > <i className="far fa-paper-plane" /> Submit </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div >
      </body >
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssignProvider);